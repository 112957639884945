import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { RichText } from "prismic-reactjs";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import Layout from "components/Layout";
import PostCard from "components/PostCard";

const Section = styled("div")`
  margin-bottom: 10rem;
  margin-top: 7rem;
  display: flex;
  flex-direction: column;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    margin-bottom: 4rem;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &.blog {
    h2 {
      text-align: center;
      padding-top: 1rem;
      @media (max-width: ${dimensions.maxwidthTablet}px) {
        padding-top: 0;
        margin-bottom: 1rem;
      }
    }
    @media (max-width: ${dimensions.maxwidthTablet}px) {
      padding: 0 1rem 2rem;
    }
    @media (max-width: ${dimensions.maxwidthMobile}px) {
      padding: 0 0 2rem;
      background: transparent;
    }
  }
`;

const BlogGrid = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;

  @media (max-width: 1050px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    grid-template-columns: 1fr;
  }
`;

const RenderBody = ({ posts, meta }) => (
  <>
    <Helmet
      title={meta.title}
      titleTemplate={meta.title}
      meta={[
        {
          name: `description`,
          content: meta.description,
        },
        {
          property: `og:title`,
          content: meta.title,
        },
        {
          property: `og:description`,
          content: meta.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: meta.author,
        },
        {
          name: `twitter:title`,
          content: meta.title,
        },
        {
          name: `twitter:description`,
          content: meta.description,
        },
      ].concat(meta)}
    />
    <Section className="blog">
      <BlogGrid>
        {posts.map((post, i) => (
          <PostCard
            key={i}
            title={post.node.post_title}
            date={post.node.post_date}
            description={post.node.post_preview_description}
            uid={post.node._meta.uid}
            image={post.node.post_hero_image}
          />
        ))}
      </BlogGrid>
    </Section>
  </>
);

export default ({ data }) => {
  //Required check for no data being returned
  const meta = data.site.siteMetadata;
  const posts = data.prismic.allPosts.edges;

  if (!posts) return null;

  return (
    <Layout>
      <RenderBody posts={posts} meta={meta} />
    </Layout>
  );
};

RenderBody.propTypes = {
  meta: PropTypes.object.isRequired,
  posts: PropTypes.array.isRequired,
};

export const query = graphql`
  {
    prismic {
      allPosts(sortBy: post_date_DESC) {
        edges {
          node {
            post_title
            post_hero_image
            post_date
            post_preview_description
            _meta {
              uid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
